import React from 'react'
import styles from './About.module.scss'

const about_items = [
    {
        title: '英文社名:',
        description: 'grid.io Inc.'
    },
    {
        title: '所在地:',
        description: '東京都品川区上大崎3-3-9　秀和目黒駅前レジデンス 519号<br/>TEL: 03-6721-7922'
    },
    {
        title: '設立年月:',
        description: '2003年5月',
    },
    {
        title: '従業員数:',
        description: '11名'
    },
    {
        title: '代表取締役:',
        description: '鍵谷 正'
    },
    {
        title: '事業内容:',
        description: 'イベント企画制作・ダンサー振付師キャスティング・アプリ制作'
    },
    {
        title: '取引銀行:',
        description: 'みずほ銀行 目黒支店、りそな銀行 目黒駅前支店'
    },
]

const history_items = [
    {
        year: '1999年',
        description: '日本で最初の初心者専門ダンススタジオ「studio HEARTS」をプロデュース'
    },
    {
        year: '2001年',
        description: 'アーティスト、ダンサ－、モデル、コンパニオンを使い、CM・展示会などのキャスティングを行う。'
    },
    {
        year: '2003年',
        description: '有限会社キーズクリエーティブスタジオ設立<br />滋慶学園グループ　ダンス専門学校をプランニング（東京葛西校・渋谷校・名古屋校）'
    },
    {
        year: '2007年',
        description: '株式会社キーズクリエーティブスタジオに商号変更'
    },
    {
        year: '2009年',
        description: '一般社団法人ストリートダンス教育研究所を設立。体育教師の為の体育教材を製作。'
    },
    {
        year: '2010年',
        description: 'アメリカダンスコミュニティー Boogiezoneと提携、さまざまなアメリカエンターテインメントに進出。'
    },
    {
        year: '2012年',
        description: 'アメリカダンスコンテスト VIBE JAPAN Dance Competitionを日本でスタート。'
    },
    {
        year: '2013年',
        description: 'California Disneylandで行われるDance the Magicプログラムを契約。日本人参加のパレード＋ステージを行う。'
    },
    {
        year: '2015年',
        description: '世界50都市で開催されている“World of Dance”と契約 日本予選を開催。'
    },
    // {
    //     year: '2015年',
    //     description: '世界50都市で開催されている“World of Dance”と契約 日本予選を開催。<br />アイドルソーシャルゲーム“あんさんぶるスターズ”のLIVEをはじめとするモーションキャプチャーの振付・ダンサー派遣を行う。'
    // },
    // {
    //     year: '2018年',
    //     description: '株式会社 grid.io 社名変更<br />ダンスフォーメーションアプリ“One Stage”リリース'
    // },
    {
        year: '2019年',
        description: '全米最大のダンスコンペティション“The Showstopper”と契約　日本予選を開催。<br />NFL Los Angeles RAMS Junior cheerプログラムを契約。'
    }
];

const AboutItem = ({item}) => {
    const title = item.title;
    const description = item.description;

    return (
        <li>
            <span>{title}</span>
            <span dangerouslySetInnerHTML={{__html: description}} />
        </li>
    );
};

const HistoryItem = ({item}) => {
    const year = item.year;
    const description = item.description;

    return (
        <li>
            <span>{year}</span>
            <span dangerouslySetInnerHTML={{__html: description}} />
        </li>
    );
};

export default () => (
    <section className={`${styles.about} l-wrapper l-container`}>
        <div>
            <h2 className={`head`}>About</h2>
            <h3 className={styles.title}>
                株式会社grid.io
                <div className={styles.logo}>
                    <img src="/assets/logo_gridio_dark.png" alt="株式会社grid.io"/>
                </div>
            </h3>
            <ul className={styles.data}>
                {about_items.map(item => <AboutItem item={item} />)}
            </ul>
            <div className={styles.divider}></div>
            <h3 className={styles.title}>History</h3>
            <ul className={styles.data}>
                {history_items.map(item => <HistoryItem item={item} />)}
            </ul>
        </div>
    </section>
)
